<div>
    <div class="adHeading" [hidden]="hideApplication">
        Application Number #<span class="adHeading" style="color: #9d050d;" >{{masterTrans?.referenceNumber}}</span>
    </div>
    <div class="adDescription" [hidden]="hideApplication">
        <span class="color-gray adDescription">{{masterTrans?.requestingPartyName}}</span>
        &nbsp;&nbsp;.&nbsp;&nbsp;
        <span class="color-gray adDescription">Type: {{masterTrans?.applicationType}}/</span>
        <span class="color-gray adDescription">{{masterTrans?.applicationSubType}}</span>
        &nbsp;&nbsp;.&nbsp;&nbsp;
        Date of Application:
        <span class="color-gray  adDescription">{{masterTrans?.dateCreatedUserTransaction | date: 'MM/dd/yyyy'}}</span>
    </div>
</div>
