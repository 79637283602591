import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Router, CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()

export class AuthenticationGuardService implements CanActivate {

  constructor(
    public auth: AuthenticationService,
    public router: Router
  ) { }

  //validated: boolean = false;

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.auth._isLoggedIn) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
