import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OldFooterComponent } from './old-footer.component';

@NgModule({
  imports: [CommonModule],
  declarations: [OldFooterComponent],
  exports: [OldFooterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OldFooterModule { }