
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { ConfigService } from '../shared/service/config.service';
@Injectable({
    providedIn: 'root'
})
export class EventService {
    constructor(public authService: AuthenticationService, public configService: ConfigService) {

    }
    EMB_FLOW_MESSAGE_SOURCE = "btd_equifax";

    PARENT_MESSAGE_TYPES = ["event", "success", "failure"];

    EMB_FLOW_MESSAGES = [
        {
            name: "fi_selection",
            description: "FI selection page viewed"
        },
        {
            name: "login_successful",//done
            description: "Successfully login user."
        },
        {
            name: "login_failed",//done
            description: "Failed to login user."
        },
        {
            name: "terms_accepted",//done
            description: "User accepted terms and conditions"
        },
        {
            name: "terms_declined",//done
            description: "Consumer declined terms & conditions"
        },
        {
            name: "widget_event", //done
            description: "BTD widget event"
        },
        {
            name: "widget_event_error", //done other than urja
            description: "BTD widget event error"
        },
        {
            name: "widget_event_success", //done
            description: "BTD widget event success"
        },
        {
            name: "widget_event_exit", //never received this event
            description: "BTD widget event exit"
        },
        {
            name: "accounts_add_more",
            description: "Add more accounts"
        },//done
        {
            name: "accounts_save",
            description: "Save accounts"
        },//done
        {
            name: "accounts_get_accounts",
            description: "Get all accounts"
        },
        {
            name: "widget_timeout",
            description: "BTD widget timeout"
        },
        {
            name: "edit_credentials",
            description: "Edit account credentials"
        },
        {
            name: "lender_selection",
            description: "Select lender"
        },//done
        {
            name: "flow_abandoned",
            description: "user has abandoned flow before submission, report will not be generated."
        },
        {
            name: "terms_displayed",
            description: "Consent was displayed to the consumer."
        }//not found in AWS
    ];

    sendEventMessageToParent(messageName, detail?) {
        this.sendMessageToParent(messageName, this.PARENT_MESSAGE_TYPES[0], detail);
    }

    sendSuccessMessageToParent(messageName, detail?) {
        this.sendMessageToParent(messageName, this.PARENT_MESSAGE_TYPES[1], detail);
    }

    sendFailureMessageToParent(messageName, detail?) {
        this.sendMessageToParent(messageName, this.PARENT_MESSAGE_TYPES[2], detail);
    }

    sendMessageToParent(messageName, type, detail?) {
        let origin: any;
        if (this.authService._isEMBFlow) {
            origin = this.authService._embFlowParentWindowLocation;
        }
        else {
            origin = window.location.origin;
        }
        const message = this.findMessageByName(messageName);
        const parentWindowMessage = {
            source: this.EMB_FLOW_MESSAGE_SOURCE,
            type: type,
            message: { ...message, detail }
        };
        console.log("===================Message Recieved===================");
        console.log(parentWindowMessage);
        if (window.opener) {
            // Message sent to window that opened new tab
            window.opener.postMessage(parentWindowMessage, origin);
        } else if (window.parent.opener) {
            // Message sent to window that opened new tab from an iframe
            window.parent.opener.postMessage(parentWindowMessage, origin);
        } else {
            // Message sent to iframe parent window
            window.parent.postMessage(parentWindowMessage, origin);
        }
    }

    isIniFrame() {
        return window.location.href !== window.parent.location.href;
    }

    findMessageByName(messageName) {
        if (!messageName) {
            return {};
        }
        const foundMessages = this.EMB_FLOW_MESSAGES.filter((message) => message.name === messageName);
        if (!foundMessages || foundMessages.length <= 0) {
            return {};
        } else {
            return foundMessages[0];
        }
    }
}
