import { Component, OnInit, Output, Input, HostListener, ElementRef, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { Router } from '@angular/router';
import { EventService } from 'src/app/services/event.service';
import { AccountCustomUiService } from 'src/app/services/account-custom-ui.service';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', '../../../assets/Shared/congrats.scss', '../../../assets/Shared/media.scss']
})

export class HeaderComponent implements OnInit {
  @Input() title: string;
  @Output() data: EventEmitter<{}> = new EventEmitter<{}>();

  @HostListener('window:resize', ['$event'])
  onResize(_event) {
    this.innerWidth = window.innerWidth;
    this.toggleMenu(false, true);
  }

  open: boolean = false;
  innerWidth;
  authenticated: boolean = false;
  isEmbdFlow: boolean = false;
  //isHideMainHeader: boolean;
  logoUrl: any;
  logoutEventObj: any;

  constructor(
    public eref: ElementRef,
    private router: Router,
    public authService: AuthenticationService,
    public accountcustomui: AccountCustomUiService,
    private eventService: EventService,
    public homeService: HomeService) { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.toggleMenu(true, false);
    this.checkAuthenticated();
    if (this.authService._isLoggedIn) {
      if (this.authService._showCustomerLogoSso == ''
       || this.authService._showCustomerLogoSso == undefined
        || this.authService._showCustomerLogoSso == null) {
        this.logoUrl = '../../assets/images/equifax_50.png';
      }
      else {
        this.logoUrl = '../../assets/images/' + this.authService._showCustomerLogoSso;
        this.title = "";
      }
    }
    else {
      this.logoUrl = '../../assets/images/equifax_50.png';
    }
    if (this.authService._account_custom_ui_Obj) {
      this.accountcustomui.customUi();
    }
  }

  checkAuthenticated() {
    this.authenticated = this.authService.isAuthenticated();
    this.isEmbdFlow = this.authService._isEMBFlow;
  }

  home() {
    // Change this route to wherever you want the user to go to when clicking the 'Equifax' icon
    this.router.navigateByUrl('/home');
  }

  toggleMenu(onload, resizeevent) {
    if ((onload && this.innerWidth > 750) || (resizeevent && this.innerWidth > 750)) {
      this.open = true;
      this.data.emit(this.open);
    } else if ((resizeevent && this.innerWidth < 750) || (onload && this.innerWidth < 750)) {
      this.open = false;
      this.data.emit(this.open);
    } else {
      this.open = !this.open;
      this.data.emit(this.open);  
    }
  }
  logoutClicked() {
    //google analytics code
    this.authService.callGoogleAnalytics('cds_click_event', '/logout', 'Logout Link Clicked');
  }
  onConfirm(): void {
    //google analytics code
    this.authService.callGoogleAnalytics('cds_page_view', '/logout', 'Logout Page');
    //google analytics code
    this.authService.callGoogleAnalytics('cds_click_event', '/logout', 'Logout-Yes Clicked');
    this.authService._isLoggedIn = false;
    this.authService._isLoggedOut = true;
    //logout event capture
    let logoutUserTID = this.authService.getRegistrationObj()?.userLogin;
    this.logoutEventObj = {
      "userTransactionId": logoutUserTID?.userTransactionId,
      "status": "Logout",
      "additionalFastlinkStatus": null,
      "providerName": null,
      "providerAccountId": null,
      "fastlinkStatusReason": null
    };
    this.homeService.sendLogBTDFastlinkEvents(this.logoutEventObj).subscribe((res: any) => {
      console.log("Logout decline event capture and send");
    });
    this.eventService.sendFailureMessageToParent('flow_abandoned');
    if (this.authService.isEMBFlow || this.authService.isSsoFlow)
      this.router.navigate(["/exit"]);
    else {
      this.router.navigate(["/login"]);
    }
  }
  noClicked() {
    //google analytics code
    this.authService.callGoogleAnalytics('cds_click_event', '/logout', 'Logout-No Clicked');
  }
}
