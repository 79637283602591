import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiEndpoints } from '../shared/api-endpoints.enum';
import { ConfigService } from '../shared/service/config.service';
import { DomSanitizer } from "@angular/platform-browser";
import { SecurityContext } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  registrationObj: any;

  accountsObj: any;
  resultsObj: any;
  iframeObj: any;
  errorObj: any;
  appDescObj: any;
  loginObj: any;
  accountCustomUi: any;
  labelObj: any;
  SSoObj: any;
  hideHeaderObj: any;
  isLoggedIn = false;
  isLoggedOut = null;
  userObj: any;
  selectedApp: any;
  isPasswordChanged = false;
  checkingAccountsObj: any;
  showMyAccounts = false;
  showMyProvider = false;
  fromEmail = false;
  userEmail: any;
  multipleAccounts = false;
  isEMBFlow = false;
  isSsoFlow = false;
  pageTitle = "Email Flow - ";
  google_analytics: any;
  showCustomerLogoSso: any;
  startTime = new Date();
  endTime: any;
  lenderObject: any;
  embFlowParentWindowLocation: any;
  //baseUrl: any = "http://localhost:3000";
  forgotPswdUser: any;
  productNm: any;
  showHeader = true;
  showFooter = true;

  constructor(private http: HttpClient, 
    private router: Router, 
    public configService: ConfigService, 
    public domSanitizer: DomSanitizer ) {
  }

  public isAuthenticated(): boolean {
    return this._isLoggedIn;
  }

  /*   setBaseAPI() {
      let data = { baseURL: this.configService.baseUrl };
      let url: string = "http://localhost:3000/";
      //url = this.configService.baseUrl + ApiEndpoints.Login;
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.post(
        this.baseUrl,
        data,
        { headers, responseType: 'json' }
      );
    } */

  // Example login function for when ready to integrate with real endpoints
  login(loginUserObject: any) {
    let url: string = "";
    url = this.configService.baseUrl + ApiEndpoints.Login;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(
      url,
      loginUserObject,
      { headers, responseType: 'json' }
    );
  }

  setRegistrationObj(obj) {
    this.registrationObj = obj;
  }

  getRegistrationObj() {
    return this.registrationObj;
  }

  set _iframeObj(iframeData: any) {
    this.iframeObj = iframeData;
  }
  get _iframeObj() {
    return this.iframeObj;
  }

  set _showMyAccounts(showMyAccounts: boolean) {
    this.showMyAccounts = showMyAccounts;
  }
  get _showMyAccounts() {
    return this.showMyAccounts;
  }

  set _showMyProvider(showMyProvider:boolean){
    this.showMyProvider = showMyProvider;
  }

  get _showMyProvider(){
    return this.showMyProvider;
  }

  set _appDescObj(appDescData: any) {
    this.appDescObj = appDescData;
  }
  get _appDescObj() {
    return this.appDescObj;
  }

  set _errorObj(error: any) {
    this.errorObj = error;
  }
  get _errorObj() {
    return this.errorObj;
  }

  set _accountsObj(accounts: any) {
    this.accountsObj = accounts;
  }
  get _accountsObj() {
    return this.accountsObj;
  }

  set _loginObj(login: any) {
    this.loginObj = login;
  }
  get _loginObj() {
    return this.loginObj;
  }

  set _account_custom_ui_Obj(customui: any) {
    this.accountCustomUi = customui;
  }
  get _account_custom_ui_Obj() {
    return this.accountCustomUi;
  }

  set _labelobj(label: any) {
    this.labelObj = label;
  }
  get _labelobj() {
    return this.labelObj;
  }

  set _ssoObj(sso: any) {
    this.SSoObj = sso;
  }
  get _ssoObj() {
    return this.SSoObj;
  }

  set _mainHeaderobj(header: any) {
    this.hideHeaderObj = header;
  }
  get _mainHeaderobj() {
    return this.hideHeaderObj;
  }

  set _resultsObj(results: any) {
    this.resultsObj = results;
  }
  get _resultsObj() {
    return this.resultsObj;
  }

  set _isLoggedIn(flag: boolean) {
    this.isLoggedIn = flag;
  }
  get _isLoggedIn() {
    return this.isLoggedIn;
  }
  set _isLoggedOut(flag: boolean) {
    this.isLoggedOut = flag;
  }
  get _isLoggedOut() {
    return this.isLoggedOut;
  }
  set _userObj(app: any) {
    this.userObj = app;
  }
  get _userObj() {
    return this.userObj;
  }
  set _selectedApp(app: any) {
    this.selectedApp = app;
  }
  get _selectedApp() {
    return this.selectedApp;
  }
  set _isPasswordChanged(flag: boolean) {
    this.isPasswordChanged = flag;
  }
  get _isPasswordChanged() {
    return this.isPasswordChanged;
  }
  set _checkingAccountsObj(accounts: any) {
    this.checkingAccountsObj = accounts;
  }
  get _checkingAccountsObj() {
    return this.checkingAccountsObj;
  }
  set _fromEmail(flag: boolean) {
    this.fromEmail = flag;
  }
  get _fromEmail() {
    return this.fromEmail;
  }
  set _userEmail(email: string) {
    this.userEmail = email;
  }
  get _userEmail() {
    return this.userEmail;
  }
  set _multipleAccounts(flag: boolean) {
    this.multipleAccounts = flag;
  }
  get _multipleAccounts() {
    return this.multipleAccounts;
  }
  set _isEMBFlow(flag: boolean) {
    this.isEMBFlow = flag;
  }
  get _isEMBFlow() {
    return this.isEMBFlow;
  }
  set _showCustomerLogoSso(logo: any) {
    this.showCustomerLogoSso = logo;
  }
  get _showCustomerLogoSso() {
    return this.showCustomerLogoSso;
  }
  get _startTime() {
    return this.startTime;
  }
  set _startTime(time: any) {
    this.startTime = time;
  }
  get _endTime() {
    return this.endTime;
  }
  set _endTime(time: any) {
    this.endTime = time;
  }
  get _lenderObject() {
    return this.lenderObject;
  }
  set _lenderObject(obj: any) {
    this.lenderObject = obj;
  }
  get _embFlowParentWindowLocation() {
    return this.embFlowParentWindowLocation;
  }
  set _embFlowParentWindowLocation(location: string) {
    this.embFlowParentWindowLocation = location;
  }
  get _google_analytics() {
    return this.google_analytics;
  }
  set _google_analytics(google_analytics: any) {
    this.google_analytics = google_analytics;
  }

  isSessionValid() {
    this._endTime = new Date();
    let diff = Math.abs(this._endTime - this._startTime);
    let minutes = Math.ceil((diff / 1000) / 60);

    if (minutes >= 30) {
      return false;
    }
    else {
      return true;
    }
  }
  nextMethod() {
    let nextProcess = true;
    if (!this._isEMBFlow) {
      let sessionValid = this.isSessionValid();
      if (!sessionValid) {
        nextProcess = false;
      }
    }
    return nextProcess;
  }
  callGoogleAnalytics(eventName: string, pageUrl: string, pageTitle: string) {
    let flowName;
    let trackingId=this.domSanitizer.sanitize(SecurityContext.HTML, this.google_analytics?.trackingId);
    if (this._isEMBFlow) {
      flowName = "EMB"
    }
    else {
      flowName = 'EMAIL'
    }
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'event': eventName,
      'pagePath': pageUrl,
      'my_page_title': this.pageTitle + pageTitle,
      'accountNumber_dimension': this.google_analytics?.accountNumber,
      'tid_dimension': this.google_analytics?.tid,
      'flow_dimension': this.google_analytics?.flowName || flowName,
      'trackingId': trackingId
    });
    (
      function (window, document, s, dataLayer, trackingId) {
        window[dataLayer] = window[dataLayer] || [];
        window[dataLayer].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });
        let f = document.getElementsByTagName(s)[0];
        let j = document.createElement(s);
        let dl = dataLayer != 'dataLayer' ? '&l=' + dataLayer : '';
        j.setAttribute('async', 'true');
        j.setAttribute('src', 'https://www.googletagmanager.com/gtm.js?id=' + trackingId + dl);
        f.parentNode.insertBefore(j, f);
      }
    )(window, document, 'script', 'dataLayer', trackingId);
  }
}
