import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AccountCustomUiService {

  
  constructor(public authService : AuthenticationService) {
    
   }

   customUi(){
    let applyCustomUiStyle = (customUiStyle) => {
        document.querySelectorAll(customUiStyle.selector).forEach(element => {
          if (customUiStyle.style) {
            element.style.cssText = customUiStyle.style;
          }
          if (customUiStyle.className) {
            element.className = element.className + ' ' + customUiStyle.className;
          }
        });
    
        if (customUiStyle.customVariables) {
          document.getElementById("efxCustomUiVariables").innerHTML = customUiStyle?.customVariables;
        }
      
    }
  
    let validateCustomUiStyle = (customUiStyles) => {
      return Array.isArray(customUiStyles);
    }
    let accountUi = this.authService._account_custom_ui_Obj;
    let accountCustomUiString = accountUi;

    try {
     let customUiStyles;
     if(accountCustomUiString){
        customUiStyles = JSON.parse(accountCustomUiString);
     }

        if (validateCustomUiStyle(customUiStyles)) {
          customUiStyles
            .filter(c => c && c.customVariables || (c.selector && (c.style || c.className)))
            .forEach(c => applyCustomUiStyle(c));
        }      
    } catch (error) {
      console.log("Custom account styles will not be applied.", error);
    }
   }


   resetStyling(){
    // document.getElementById("efxCustomUiVariables").innerHTML=`:root {

    //   /* COLORS */
    
    //   /* Primary */
    //   --efxPrimary: #0076A3;
    
    //   /* Status */
    //   --efxStatus: #3FAE29;
    
    //   /* Text */
    //   --efxTextDark: #333D47;
    //   --efxTextLight: #5F6A72;
    //   --efxTextError: #80001C;
    
    //   /* Interactive */
    //   --efxInteractiveLight: #0093C9;
    //   --efxInteractiveDark: #005670;
    //   --efxInteractiveFocus: #0F62FE;
    //   --efxInteractiveDisabled: #E5EBED;
    
    //   /* UI */
    //   --efxBackgroundError: #FFEBEF;
    //   --efxBackgroundDark: #CED4DA;
    //   --efxBackgroundLight: #FFFFFF;
    
    //   /* SIZES */
    //   --efxFontSize: 16px;
    //   --efxLineHeight: 24px;
    //   --efxInputRadius: 4px;
    //   --efxContainerPadding: 30px 16px;
    // }`;

   }
  
}
