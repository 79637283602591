import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { AccountCustomUiService } from '../services/account-custom-ui.service';

@Component({
  selector: 'app-application-desc',
  templateUrl: './application-desc.component.html',
  styleUrls: ['./application-desc.component.scss']
})
export class ApplicationDescComponent implements OnInit {
  masterTrans: any;
  hideApplication: boolean = true;

  constructor(public authService: AuthenticationService, public accountcustomService: AccountCustomUiService,
  ) {
    this.masterTrans = authService._appDescObj;
  }

  ngOnInit(): void {
    this.accountcustomService.customUi();

    let hideApp = this.authService._loginObj;
    if (hideApp == "true") {
      this.hideApplication = true;
    }
    else {
      this.hideApplication = false;
    }
  }
}
