import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuardService } from './authentication/authentication-guard.service';
import { ForgotPwdComponent } from './forgot-pwd/forgot-pwd.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  {

    path: 'registration/:id',
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./error-handler/error-handler.module').then(m => m.ErrorHandlerModule),
  },
  {
    path: 'token-validation/:id',
    loadChildren: () => import('./token-validation/token-validation.module').then(m => m.TokenValidationModule),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/login',
  },
  {
    path: 'btd-urjanet',
    canActivate: [AuthenticationGuardService],
    loadChildren: () => import('./btd-urjanet/btd-urjanet.module').then(m => m.BtdUrjanetModule),
  },
  {
    path: 'urjanet-success',
    canActivate: [AuthenticationGuardService],
    loadChildren: () => import('./urjanet-success/urjanet-success.module').then(m => m.UrjanetSuccessModule),
  },
  {
    path: 'urjanet-providers',
    loadChildren: () => import('./my-providers/my-providers.module').then(m => m.MyProvidersModule),
  },
  {
    path: 'urjanet-success',
    canActivate: [AuthenticationGuardService],
    loadChildren: () => import('./urjanet-success/urjanet-success.module').then(m => m.UrjanetSuccessModule),
  },
  {
    path: 'fastlink',
    canActivate: [AuthenticationGuardService],
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'fi-error',
    canActivate: [AuthenticationGuardService],
    loadChildren: () => import('./fastlink-error/fastlink-error.module').then(m => m.FastlinkErrorModule),
  },
  {
    path: 'lenderDashboard',
    canActivate: [AuthenticationGuardService],
    loadChildren: () => import('./app-dash-selection/app-dash.module').then(m => m.AppDashModule),
  },
  {
    path: 'loading',
    canActivate: [AuthenticationGuardService],
    loadChildren: () => import('./loading/loading.module').then(m => m.LoadingModule),
  },
  {
    path: 'accounts',
    canActivate: [AuthenticationGuardService],
    loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule),
  },
  {
    path: 'checkingAccounts',
    canActivate: [AuthenticationGuardService],
    loadChildren: () => import('./checking-accounts/checking-accounts.module').then(m => m.CheckingAccountsModule),
  },
  {
    path: 'success',
    canActivate: [AuthenticationGuardService],
    loadChildren: () => import('./success/success.module').then(m => m.SuccessModule),
  },
  {
    path: 'emb-flow/terms',
    loadChildren: () => import('./emb-flow-terms/emb-flow-terms.module').then(m => m.EmbFlowTermsModule)
  },
  {
    path: 'sso',
    loadChildren: () => import('./sso-terms/sso-terms.module').then(m => m.SsoTermsModule)
  },
  {
    path: 'exit',
    loadChildren: () => import('./exit/exit.module').then(m => m.ExitModule),
  },
  {
    path: 'terms',
    canActivate: [AuthenticationGuardService],
    loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-pwd/forgot-pwd.module').then(m => m.ForgotPwdModule),
  },

  {
    path: '**',
    redirectTo: '',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
