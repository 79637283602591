// Angular Imports
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Providers / Services
import { AuthenticationService } from './authentication/authentication.service';
import { AuthenticationGuardService } from './authentication/authentication-guard.service';

// Components
import { BtdUrjanetComponent } from './btd-urjanet/btd-urjanet.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { TokenValidationComponent } from './token-validation/token-validation.component';
import { ErrorHandlerComponent } from './error-handler/error-handler.component';
import { FastlinkErrorComponent } from './fastlink-error/fastlink-error.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { ConfigService } from './shared/service/config.service';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { RouterModule } from '@angular/router';
import { HeaderModule } from './shared/header/header.module';
import { OldFooterModule } from './shared/old-footer/old-footer.module';
import { AppDescModule } from './application-desc/application-desc.module';
import { MyProvidersComponent } from './my-providers/my-providers.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HeaderModule,
    OldFooterModule,
    AppDescModule,
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    })
  ],
  providers: [
    AuthenticationGuardService,
    AuthenticationService,
    ConfigService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.cds.siteKey,
      } as RecaptchaSettings,
    },
    { provide: 'BACKEND_API_URL', useValue: environment.BACKEND_API || window.location.origin },
    DatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
