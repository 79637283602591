<div [hidden]="this.authService._mainHeaderobj==='true'">
    <div class="top_nav">
        <div class="top_nav_left">
            <div class="logo"><img (click)="home()" [src]='logoUrl' alt='logo'>
            </div>
            <div class="logo_text"><span>{{title}}</span></div>
        </div>
        <div class="top_nav_right">
            <div class="user">
                <div id="cdsButton_logout" style="display: inline-block; cursor: pointer;" class="dropbtn"
                    data-toggle="modal" data-target="#logoutModal">
                    <img *ngIf="authenticated && !isEmbdFlow" id="cdsButton_logout" src=".../../assets/images/user.png"
                        alt="logout" />
                    <span *ngIf="authenticated && !isEmbdFlow" id="cdsButton_logout">Logout</span>
                </div>

                <div [hidden]="isEmbdFlow" class="top_icon" style="padding-top: 14px;">
                    <div class="help"><img src=".../../assets/images/help.png" alt="help" /></div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="logoutModal" tabindex="-1" aria-labelledby="logoutModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content efxModalContent">
            <div class="efxModalHeader">
                <div class="efxModalTitle">
                    <span id="modalTitle">Are you sure you want to exit?</span>
                </div>
                <button id="cdsButton_efxModalCloseBtn" class="efxModalClose" type="button"
                    data-dismiss="modal">&times;</button>
            </div>
            <div id="efxModalDescription" class="efxModalDescription">
                Your application will be incomplete
            </div>
            <div id="efxModalButton1" class="efxModalButton1">
                <button data-dismiss="modal" id="cdsButton_doneModal_button1"
                    class="efxButton efxButtonPrimary efxButtonFullWidth" (click)="onConfirm()"> Yes
                </button>
            </div>
            <div id="efxModalButton2" class="efxModalButton2">
                <button data-dismiss="modal" id="cdsButton_doneModal_button2"
                    class="efxButton efxButtonSecondary efxButtonFullWidth" (click)="noClicked()"> No
                </button>
            </div>
        </div>
    </div>
</div>
