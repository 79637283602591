import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationDescComponent } from './application-desc.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ApplicationDescComponent],
  exports: [ApplicationDescComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppDescModule { }