import { Component, HostListener } from '@angular/core';
import { AuthenticationService } from './authentication/authentication.service';
import { EventService } from './services/event.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./shared/header/header.component.scss',
    './shared/old-footer/old-footer.component.scss']
})
export class AppComponent {
  authenticated: any;
  isEmbdFlow: any;
  constructor(private readonly eventService: EventService, public authService: AuthenticationService) {
    if (this.authService.getRegistrationObj() != undefined) {
      this.authService.productNm = this.authService.getRegistrationObj().isShowHeading;
      this.authenticated = this.authService.isAuthenticated();
      this.isEmbdFlow = this.authService._isEMBFlow;
    }
  }
  title = 'CDS';

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler() {
    this.eventService.sendFailureMessageToParent('flow_abandoned');
  }
}
