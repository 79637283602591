import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { AccountCustomUiService } from 'src/app/services/account-custom-ui.service';

@Component({
  selector: 'old-footer',
  templateUrl: './old-footer.component.html',
  styleUrls: ['./old-footer.component.scss','../../../assets/Shared/congrats.scss','../../../assets/Shared/media.scss']
})
export class OldFooterComponent {

  constructor(private readonly router: Router, public authService: AuthenticationService, public accountcustomui:AccountCustomUiService) { 
    if(this.authService._account_custom_ui_Obj){
      this.accountcustomui.customUi();
    }
  }

  gotoTerms() {
    this.router.navigateByUrl('/terms');
  }

}
