import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpoints } from '../shared/api-endpoints.enum';
import { ConfigService } from '../shared/service/config.service';
import { DomSanitizer } from "@angular/platform-browser";
import { SecurityContext } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class HomeService {
  constructor(private readonly http: HttpClient, public configService: ConfigService,public domSanitizer: DomSanitizer) { }

  getDetails(id: string) {
    let url: string = "";
    url = this.configService.baseUrl + ApiEndpoints.Home;

    let httpHeaders = new HttpHeaders({ 'accept': 'application/json', 'correlationId': '' + id });
    return this.http.post(
      url,
      null,
      { headers: httpHeaders }
    );
  }

  getAccounts(firstName: string, _providerAccountId: string, transData: any) {
    let obj = {
      "userTransactionId": transData?.userTransactionId,
      "productCd": transData?.productCode,
      "yodleeUserName": transData?.yodleeUsername,
      "firstName": firstName
      //,"selectedIDsFromSession":[providerAccountId]
    }
    let accountsUrl = this.configService.baseUrl + ApiEndpoints.GetAccounts;
    const headers = new HttpHeaders({ 'accept': 'application/json', 'Content-Type': 'application/json', 'correlationId': '' + transData?.userTransactionId });
    return this.http.post(
      accountsUrl,
      obj,
      { headers, responseType: 'json' }
    );
  }

  getProperty(propertyName: string) {
    let url: string = "";
    url = this.configService.baseUrl + ApiEndpoints.PropertyName;

    const headers = new HttpHeaders({ 'accept': 'application/json', 'Content-Type': 'application/json' });
    let payload = {
      "propertyName": propertyName
    }
    return this.http.post(
      url,
      payload,
      { headers }
    );
  }

  sendLogBTDFastlinkEvents(eventObj:any) {
    let url: string = "";
    url = this.configService.baseUrl + ApiEndpoints.LogBTDFastlinkEvents;
    let providerAccId= eventObj.providerAccountId?.toString();
    let userTransactionId=this.domSanitizer.sanitize(SecurityContext.HTML,  eventObj.userTransactionId);
    const headers = new HttpHeaders({ 'accept': 'application/json', 'Content-Type': 'application/json', 'correlationId': '' + userTransactionId });
    let payload = {
      "userTransactionId": eventObj.userTransactionId,
      "status": eventObj.status,
      "additionalFastlinkStatus": eventObj.additionalFastlinkStatus,
      "providerName": eventObj.providerName,
      "providerAccountId": providerAccId,
      "providerId": eventObj.providerId,
      "fastlinkStatusReason": eventObj.fastlinkStatusReason
    }
    return this.http.post(
      url,
      payload,
      { headers }
    );
  }
}
