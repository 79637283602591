import { Injectable, Inject  } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class ConfigService {

  baseUrl:string;
  constructor(@Inject('BACKEND_API_URL') public BACKEND_API: string) {
    console.log("******* BACKEND SERVICE URL ********");
    if (environment.isLocal) {
      this.baseUrl = environment.BACKEND_API;
      console.log("BACKEND API BASE URL:"+this.baseUrl);
    }
    else {
      this.baseUrl = this.BACKEND_API;
      console.log("BACKEND API BASE URL:"+this.baseUrl);
    }
  }
}
