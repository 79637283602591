export enum ApiEndpoints {
    Login = '/v1/login',
    Home = '/v1/user/landing',
    Signup = '/v1/user/signup',
    ConfirmToken = '/v1/user/confirmuser',
    ResendToken = '/v1/token/resend',
    ProviderAccount = '/v1/providerAccounts',
    GetAccounts = '/v1/get/myaccounts',
    ForgotPassword = '/v1/user/forgot',
    ResetPassword = '/v1/user/reset',
    ForgotPasswordToken = "/v1/token/forgot/resend",
    SaveAccounts = '/v1/save/accounts',
    LenderSelection = '/v1/submit/lenderSelection',
    AcceptConsent = '/v1/login/acceptConsent',
    LoadIframe = '/v1/load/iframe',
    CheckingAccounts = '/v1/get/myaccountsChecking',
    EmdFlowLogin = '/emb-flow/login',
    SsoFlowLogin = '/sso/login',
    PropertyName = '/v1/user/property',
    EditCredentials = '/v1/edit/credentials',
    LogBTDFastlinkEvents = '/v1/capture-fastlink-events/save/logBTDFastlinkEvents',
    SaveUrjProviders = '/emb-flow/save/urj/providers'
}
